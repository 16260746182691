<template>
  <div>
    <!-- 语言选择 -->
    <!-- @click="$router.push('/language')" -->
    <div
      class="language"
      @click="showList()"
    >
      <div class="flex flex-b">
        <!-- 消息图标 -->
        <div
          v-if="showMsg"
          v-show="showIcon"
          class="icon msg"
          @click="$router.push('/notice')"
        />

        <div
          v-if="showMsgs"
          class="icon msgs"
          @click="$router.push('/notice')"
        />

        <div
          v-if="showLang"
          class="icon"
          :class="showMsg ? 'lang1' : 'lang'"
        />
        <div
          v-if="showLang"
          class="txt"
          :class="{ txts: showMsg }"
        >
          {{ langNameList[langKey] }}
        </div>
        <div
          v-if="showLang"
          class="icon"
          :class="showMsg ? 'xl1' : 'xl'"
        />
      </div>
    </div>

    <van-popup
      v-model="show"
      round
      position="bottom"
    >
      <!-- 选择区号 -->
      <van-picker
        title=""
        show-toolbar
        :columns="columns"
        :confirm-button-text="$t('utils.confirm')"
        :cancel-button-text="$t('utils.cancel')"
        :default-index="defaultIndex"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import Fetch from "../utils/fetch";
import Vue from "vue";
import { Popup, Picker } from "vant";
Vue.use(Popup).use(Picker);
export default {
  name: "LangSelect",
  components: {},
  props: {
    showMsg: {
      type: Boolean,
      default: false,
    },
    showMsgs: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    showLang: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      defaultIndex: 0,
      show: false,
      langKey: "",
      langNameList: {},
      languages: [],
      columns: [],
      country: "",
    };
  },
  computed: {},
  created() {
    this.getList();
  },
  methods: {
    onConfirm(value) {
      let name = "";
      this.languages.forEach((item) => {
        if (item.country_loc == value) {
          name = item.country;
        }
      });
      this.country = value;
      this.changeLang(name);
      this.langKey = name;
      this.show = false;
    },
    onCancel() {
      this.show = false;
    },
    showList() {
      this.langKey = localStorage.getItem("lang") || "vi_vn";
      this.defaultIndex = this.columns.indexOf(this.langNameList[this.langKey]);
      this.show = true;
    },
    changeLang(lang) {
      this.lang = lang;
      this.$i18n.locale = lang;
      localStorage.setItem("lang", lang);

      Fetch("/index/changeLang").then((r) => {
        localStorage.setItem("currency", "");
        localStorage.setItem("precision", r.data.precision);
        localStorage.setItem("language_logo", r.data.language_logo);
        // this.$router.go(0);
        location.reload(); //不需要加window，在iOS端会不触发
      });
    },
    getList() {
      // 获取语言列表
      Fetch("/index/getLanguages").then((r) => {
        // this.languages = r.data.list;
        this.languages = r.data.list;
        // .map((item, i) => {
        //   // 只保留最前面四种语言 中 英 越 丹麦
        //   if (i <= 3) {
        //     return item;
        //   }
        // })
        // .filter((item) => item);

        let arr = [];
        // 获取选择语言的显示内容
        this.languages.forEach((item) => {
          this.langNameList[item.country] = item.country_loc;
          arr.push(item.country_loc);
        });
        this.columns = [...new Set(arr)];
        // console.log(this.columns)
        this.langKey = localStorage.getItem("lang") || "vi_vn";
        // console.log('this.langKey',this.langKey)
      });
    },
  },
};
</script>

<style scoped lang="less">
::v-deep .van-overlay {
  background-color: rgba(0, 0, 0, 0.3);
}
::v-deep .van-picker__cancel {
  color: #333;
}
::v-deep .van-picker__confirm {
  color: #2333d1;
}
.language {
  position: absolute;
  top: 16px;
  right: 10px;
  height: 18px;
  z-index: 11;
  .txt {
    margin: 0 5px;
    color: #333333;
    font-size: 14px;
    &.txts {
      color: #fff;
    }
  }
}
</style>
