<template>
  <div>
    <div
      class="top"
      :class="{ hs: isBg, white: isWhite }"
    >
      <div
        class="icon"
        :class="isWhite ? 'jts' : 'jt'"
        @click="goBack"
      />
      {{ $t(title) }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TopBack",
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    isBg: {
      type: Boolean,
      default: false,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    isAuth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    goBack() {
      // 在实名认证界面默认返回我的
      if (this.isAuth) {
        this.$emit("back");
        return;
      }
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="less">
.top {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  z-index: 99;
  text-align: center;
  line-height: 50px;
  font-size: 16px;
  color: #333333;
  background-color: #fff;
  // font-family: PingFang-SC-Bold PingFang SC;
  font-weight: bold;
  &.hs {
    background-color: #f0f0f0;
  }
  &.white {
    background-color: transparent;
    position: relative;
    color: #ffffff;
  }
  .icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
