<template>
  <div id="app">
    <router-view />

    <div
      v-show="is_show_footer"
      id="footer"
      class="footer"
    >
      <router-link
        to="/"
        class="footer_item"
        :class="show_icon_type === 'index' ? 'footer_item_active' : ''"
      >
        <span class="footer_icon footer_icon_home" />
        <p class="footer_info">
          {{ $t("home.index") }}
        </p>
      </router-link>
      <router-link
        to="/invest"
        class="footer_item"
        :class="show_icon_type === 'invest' ? 'footer_item_active' : ''"
      >
        <span class="footer_icon footer_icon_invest" />
        <p class="footer_info">
          {{ $t("home.invest") }}
        </p>
      </router-link>

      <!-- 新增客服 -->
      <router-link
        to="/service"
        class="footer_item"
        :class="show_icon_type === 'kefu' ? 'footer_item_active' : ''"
      >
        <span class="footer_icon footer_icon_serve" />
        <p class="footer_info">
          {{ $t("new.kefu") }}
        </p>
      </router-link>

      <!-- <router-link
        to="/team"
        class="footer_item"
        :class="show_icon_type === 'team' ? 'footer_item_active' : ''"
      >
        <span class="footer_icon footer_icon_team" />
        <p class="footer_info">
          {{ $t("home.team") }}
        </p>
      </router-link> -->
      <router-link
        to="/user"
        class="footer_item"
        :class="show_icon_type === 'user' ? 'footer_item_active' : ''"
      >
        <span class="footer_icon footer_icon_user" />
        <p class="footer_info">
          {{ $t("home.my") }}
        </p>
      </router-link>
    </div>

    <!-- 加载图片 -->
    <!-- <div v-if="show_loading_wrap" class="loading_wrap">
      <img :src="require('./views/img/index/loading.gif' + '')" alt="" />
    </div> -->
  </div>
</template>

<script>
import Fetch from "./utils/fetch";

export default {
  name: "App",
  data() {
    return {
      show_icon_type: "index",
      is_show_footer: true,
      show_loading_wrap: true,
      lang: "vi_vn",
    };
  },
  updated() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
  mounted() {
    this.start();
  },
  methods: {
    onSwipeLeft(even, start, end) {
      if (end["X"] - start["X"] > 60) {
        this.$router.go(-1);
      }
    },
    footer: function (type = "index", show_footer = true) {
      this.show_icon_type = type;
      this.is_show_footer = show_footer;
    },
    start() {
      // //首次启动，判断浏览器语言，自动切换成浏览器语言
      // var lang1 = navigator.language;
      // if (localStorage.getItem("lang") == null && this.langs[lang1] != 'undefined') {
      // 	this.$i18n.locale = this.langs[lang1];
      // 	localStorage.setItem("lang", this.langs[lang1]);
      // }
      var reload = false;
      if (localStorage.getItem("reload")) {
        reload = true;
      }
      Fetch(
        "/index/webconfig",
        {
          // reload: reload
        },
        "",
        false
      ).then((r) => {
        this.setLang(r.data.language);
        window.document.title = r.data.webname;
        localStorage.setItem("logo", r.data.logo);
        localStorage.setItem("logo2", r.data.logo2);
        localStorage.setItem("currency", "");
        localStorage.setItem("precision", r.data.precision);
        localStorage.setItem("currency_symbol_basic", "$");
        localStorage.setItem("precision_basic", 2);
        localStorage.setItem("language_logo", r.data.language_logo);
        localStorage.setItem("webname", r.data.webname);
      });
    },
    setLang(lang) {
      if (!localStorage.getItem("lang")) {
        localStorage.setItem("lang", lang);
        this.$i18n.locale = lang;
        localStorage.setItem("reload", true);
        location.reload();
      } else {
        this.show_loading_wrap = false;
      }
    },
  },
};
</script>
<style lang="less">
// @import "./assets/font/stylesheet.css";
// 弹窗遮罩样式
.van-overlay {
  background-color: rgba(0, 0, 0, 0.3);
}
* {
  font-family: PingFang-SC-Medium, PingFang SC;
}

.top-fixed {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 99;
}
.bot-fixed {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 99;
}
.pad-top {
  padding-top: 50px;
}
.flex {
  display: flex;
  align-items: center;
  &.flex-a {
    justify-content: space-around;
  }
  &.flex-b {
    justify-content: space-between;
  }
  &.flex-c {
    justify-content: center;
  }
}
.flex-3 {
  flex: 3;
}
.flex-2 {
  flex: 2;
}
.flex-1 {
  flex: 1;
}

.t-center {
  text-align: center;
}
.t-right {
  text-align: right;
}
// 无数据
.no-data {
  margin: 100px 0;
  .no-img {
    width: 130px;
    height: 90px;
    background: url("../src/assets/imgs/wujilu.png") no-repeat center/100%;
    margin: auto;
  }
  .no-txt {
    margin-top: 20px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
    text-align: center;
  }
}
.submit-btn {
  width: 90%;
  padding: 15px 0;
  background: linear-gradient(90deg, #606fff, #7a82fc);
  border-radius: 22px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  margin: 20px auto;
}

.icon {
  &.zs {
    width: 15px;
    height: 15px;
    background: url("../src/assets/imgs/zs.png") no-repeat;
    background-size: cover;
  }
  &.q1 {
    width: 20px;
    height: 20px;
    background: url("../src/assets/imgs/q.png") no-repeat center/100%;
  }
  &.q1 {
    width: 20px;
    height: 20px;
    background: url("../src/assets/imgs/q1.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.q2 {
    width: 20px;
    height: 20px;
    background: url("../src/assets/imgs/q2.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.q3 {
    width: 20px;
    height: 20px;
    background: url("../src/assets/imgs/q3.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.q4 {
    width: 20px;
    height: 20px;
    background: url("../src/assets/imgs/q4.png") no-repeat center/100%;
    margin-right: 5px;
  }

  &.hbbg {
    width: 100%;
    height: 259px;
    background: url("../src/assets/imgs/redbag.png") no-repeat center/100%;
    background-size: contain;
  }
  &.xzq {
    width: 15px;
    height: 15px;
    background: url("../src/assets/imgs/yixz.png") no-repeat center/100%;
    margin-right: 5px;
  }

  // 团队等级列表前面的小图标的动画
  &.active {
    transform: rotate(90deg);
    transition: transform 0.3s;
  }
  &.l1 {
    width: 6px;
    height: 9px;
    background: url("../src/assets/imgs/1j.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.l2 {
    width: 6px;
    height: 9px;
    background: url("../src/assets/imgs/2j.png") no-repeat center/100%;
    margin-right: 5px;
    margin-left: 5px;
  }
  &.l3 {
    width: 6px;
    height: 9px;
    background: url("../src/assets/imgs/3j.png") no-repeat center/100%;
    margin-right: 5px;
    margin-left: 10px;
  }
  &.l4 {
    width: 6px;
    height: 9px;
    background: url("../src/assets/imgs/4j.png") no-repeat center/100%;
    margin-right: 5px;
    margin-left: 15px;
  }
  &.l5 {
    width: 6px;
    height: 9px;
    // background: url("../src/assets/imgs/5j.png") no-repeat center/100%;
    margin-right: 5px;
    margin-left: 20px;
  }

  &.tdlb {
    width: 21px;
    height: 23px;
    background: url("../src/assets/imgs/tdlv.png") no-repeat center/100%;
  }
  &.codebg {
    width: 280px;
    height: 335px;
    background: url("../src/assets/imgs/erwm.png") no-repeat center/100%;
    margin: auto;
  }
  &.code {
    width: 20px;
    height: 20px;
    background: url("../src/assets/imgs/qrcode.png") no-repeat center/100%;
    margin-left: 5px;
  }
  &.dw {
    width: 13px;
    height: 15px;
    background: url("../src/assets/imgs/dingw.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.xz {
    width: 9px;
    height: 15px;
    background: url("../src/assets/imgs/jinr.png") no-repeat center/100%;
    margin-left: 5px;
  }

  &.bj {
    width: 14px;
    height: 14px;
    background: url("../src/assets/imgs/bianj.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.sc {
    width: 14px;
    height: 14px;
    background: url("../src/assets/imgs/shanc.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.jtjj {
    width: 17px;
    height: 17px;
    background: url("../src/assets/imgs/jituan1.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.zzry {
    width: 17px;
    height: 17px;
    background: url("../src/assets/imgs/rongyu.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.cztx {
    width: 17px;
    height: 17px;
    background: url("../src/assets/imgs/jine1.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.rhzc {
    width: 17px;
    height: 17px;
    background: url("../src/assets/imgs/zhuc.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.safe {
    width: 17px;
    height: 17px;
    background: url("../src/assets/imgs/safe.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.tcbg {
    width: 100%;
    height: 430px;
    background: url("../src/assets/imgs/tc.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.zjmm {
    width: 17px;
    height: 17px;
    background: url("../src/assets/imgs/zijin.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.dlmm {
    width: 17px;
    height: 17px;
    background: url("../src/assets/imgs/jituan.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.qchc {
    width: 17px;
    height: 17px;
    background: url("../src/assets/imgs/huanc.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.jtrs {
    width: 7px;
    height: 12px;
    background: url("../src/assets/imgs/jiantr1.png") no-repeat center/100%;
    margin-left: 5px;
  }

  &.dhcg {
    width: 66px;
    height: 66px;
    background: url("../src/assets/imgs/dhcg.png") no-repeat center/100%;
  }
  &.gb {
    width: 17px;
    height: 17px;
    background: url("../src/assets/imgs/guanbi.png") no-repeat center/100%;
  }
  &.xybg {
    width: 100%;
    height: 790px;
    background: url("../src/assets/imgs/dbj.png") no-repeat center/100%;
    background-size: cover;
  }
  &.qdbg {
    width: 100%;
    height: 240px;
    background: url("../src/assets/imgs/bjtu2.png") no-repeat center/100%;
  }
  &.qdgz {
    width: 14px;
    height: 17px;
    background: url("../src/assets/imgs/guz.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.yqbg {
    width: 100%;
    height: 100vh;
    background: url("../src/assets/imgs/bejtu.png") no-repeat;
    background-size: cover;
  }
  &.qrbg {
    width: 100%;
    height: 60%;
    background: url("../src/assets/imgs/beit.png") no-repeat;
    background-size: cover;
  }
  &.zjjl {
    width: 165px;
    height: 36px;
    background: url("../src/assets/imgs/biaoq.png") no-repeat center/100%;
  }

  &.index0 {
    // width: 172px;
    width: 100%;
    height: 90px;
    background: url("../src/assets/imgs/livbj.png") no-repeat center/100%;
  }
  &.index1 {
    // width: 172px;
    width: 100%;
    height: 90px;
    background: url("../src/assets/imgs/zbj.png") no-repeat center/100%;
  }
  &.index2 {
    // width: 172px;
    width: 100%;
    height: 90px;
    background: url("../src/assets/imgs/chbj.png") no-repeat center/100%;
  }
  &.index3 {
    // width: 172px;
    width: 100%;
    height: 90px;
    background: url("../src/assets/imgs/zhbj.png") no-repeat center/100%;
  }
  &.jx {
    width: 21px;
    height: 23px;
    background: url("../src/assets/imgs/baioq.png") no-repeat center/100%;
  }
  &.tj {
    width: 21px;
    height: 23px;
    background: url("../src/assets/imgs/rmbq.png") no-repeat center/100%;
  }
  &.jlzd {
    width: 60px;
    height: 60px;
    background: url("../src/assets/imgs/jiangl.png") no-repeat center/100%;
  }
  &.mrqd {
    width: 60px;
    height: 60px;
    background: url("../src/assets/imgs/qinad.png") no-repeat center/100%;
  }
  &.cz1 {
    width: 60px;
    height: 60px;
    background: url("../src/assets/imgs/chongz1.png") no-repeat center/100%;
  }
  &.tx1 {
    width: 60px;
    height: 60px;
    background: url("../src/assets/imgs/tixian1.png") no-repeat center/100%;
  }
  &.yqhy {
    width: 60px;
    height: 60px;
    background: url("../src/assets/imgs/yaoqhy.png") no-repeat center/100%;
  }
  &.xycj {
    width: 60px;
    height: 60px;
    background: url("../src/assets/imgs/chouj.png") no-repeat center/100%;
  }
  &.yebg {
    width: 100%;
    height: 500px;
    background: url("../src/assets/imgs/yebj.png") no-repeat center/100%;
  }
  &.sscq {
    width: 26px;
    height: 26px;
    background: url("../src/assets/imgs/cunqu.png") no-repeat center/100%;
  }
  &.sxtz {
    width: 26px;
    height: 26px;
    background: url("../src/assets/imgs/tz.png") no-repeat center/100%;
  }
  &.hsy {
    width: 26px;
    height: 26px;
    background: url("../src/assets/imgs/shouy.png") no-repeat center/100%;
  }
  &.wdsy {
    width: 21px;
    height: 23px;
    background: url("../src/assets/imgs/quanyi.png") no-repeat center/100%;
  }
  &.jymx {
    width: 14px;
    height: 17px;
    background: url("../src/assets/imgs/mingxi.png") no-repeat center/100%;
  }
  &.wjlc {
    // width: 148px;
    height: 36px;
    background: url("../src/assets/imgs/bj1.png") no-repeat;
    background-size: 100% 100%;
  }
  &.jft {
    width: 26px;
    height: 21px;
    background: url("../src/assets/imgs/jif.png") no-repeat center/100%;
  }
  &.jfbg {
    width: 100%;
    height: 150px;
    background: url("../src/assets/imgs/jfbj.png") no-repeat center/100%;
  }

  &.ysy {
    width: 100%;
    height: 90px;
    background: url("../src/assets/imgs/ysy.png") no-repeat center/100%;
  }

  &.jxq {
    width: 100%;
    height: 90px;
    background: url("../src/assets/imgs/jixq.png") no-repeat center/100%;
  }
  &.yhq {
    width: 100%;
    height: 90px;
    background: url("../src/assets/imgs/yhq.png") no-repeat center/100%;
  }

  &.kqbg {
    width: 100vw;
    height: 240px;
    background: url("../src/assets/imgs/bjt.png") no-repeat center/100%;
  }
  &.wdqy {
    width: 21px;
    height: 23px;
    background: url("../src/assets/imgs/quanyi.png") no-repeat center/100%;
  }
  &.vipbg {
    width: 100vw;
    height: 300px;
    background: url("../src/assets/imgs/huiybj.png") no-repeat center/100%;
  }
  &.vip {
    width: 83px;
    height: 27px;
    background: url("../src/assets/imgs/hyuanbjj.png") no-repeat center/100%;
  }
  &.auth {
    width: 95px;
    height: 76px;
    background: url("../src/assets/imgs/renz.png") no-repeat center/100%;
  }
  &.cbg {
    width: 100%;
    height: 90px;
    background: url("../src/assets/imgs/bdyhk.png") no-repeat center/100%;
  }
  &.cz {
    width: 32%;
    height: 48px;
    background: url("../src/assets/imgs/chongz.png") no-repeat center/100%;
    line-height: 48px;
  }
  &.tx {
    width: 32%;
    height: 48px;
    background: url("../src/assets/imgs/tixian.png") no-repeat center/100%;
    // margin: 0 10px;
    line-height: 48px;
  }
  &.zz {
    width: 32%;
    height: 48px;
    background: url("../src/assets/imgs/zhuanz.png") no-repeat center/100%;
    line-height: 48px;
  }

  &.td {
    display: inline-block;
    vertical-align: middle;
    width: 13px;
    height: 12px;
    background: url("../src/assets/imgs/tuand.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.dj {
    display: inline-block;
    vertical-align: middle;
    width: 13px;
    height: 12px;
    background: url("../src/assets/imgs/huiy.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.jt {
    width: 9px;
    height: 16px;
    background: url("../src/assets/imgs/fanhui.png") no-repeat center/100%;
  }
  &.jtr {
    width: 9px;
    height: 16px;
    background: url("../src/assets/imgs/jiant.png") no-repeat center/100%;
    margin-left: 5px;
  }
  &.jts {
    width: 9px;
    height: 16px;
    background: url("../src/assets/imgs/fanhb.png") no-repeat center/100%;
  }
  &.tzbg {
    width: 100vw;
    height: 370px;
    background: url("../src/assets/imgs/bj.png") no-repeat center/100%;
  }
  &.jl {
    width: 15px;
    height: 15px;
    background: url("../src/assets/imgs/jil.png") no-repeat center/100%;
  }
  &.tzje {
    width: 12px;
    height: 12px;
    background: url("../src/assets/imgs/jine.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.tzzq {
    width: 12px;
    height: 12px;
    background: url("../src/assets/imgs/zhouq.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.tz {
    width: 10px;
    height: 10px;
    background: url("../src/assets/imgs/touz.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.msg {
    width: 18px;
    height: 18px;
    background: url("../src/assets/imgs/xiaox.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.msgs {
    width: 18px;
    height: 18px;
    background: url("../src/assets/imgs/xxx.png") no-repeat center/100%;
    margin-right: 5px;
  }
  &.logbg {
    width: 320px;
    height: 477px;
    background: url("../src/assets/imgs/zhucks.png") no-repeat;
    background-size: 100% 100%;
    // transform: rotateY(180deg);
  }
  &.regbg {
    width: 320px;
    height: 477px;
    background: url("../src/assets/imgs/zhuck.png") no-repeat;
    background-size: 100% 100%;
  }

  &.lang {
    width: 18px;
    height: 18px;
    background: url("../src/assets/imgs/yuyan.png") no-repeat center/100%;
  }
  &.lang1 {
    width: 18px;
    height: 18px;
    background: url("../src/assets/imgs/yuyan2.png") no-repeat center/100%;
  }
  &.xl {
    width: 11px;
    height: 6px;
    background: url("../src/assets/imgs/xl.png") no-repeat center/100%;
  }
  //下拉白色图标
  &.xl1 {
    width: 11px;
    height: 6px;
    background: url("../src/assets/imgs/fanhui2.png") no-repeat center/100%;
  }

  &.bg {
    width: 100vw;
    height: 100vh;
    background: url("../src/assets/imgs/bjtu.png") no-repeat;
    background-size: 100% 100%;
  }
  &.fj {
    width: 50px;
    height: 50px;
    background: url("../src/assets/imgs/tele.png") no-repeat center/100%;
  }
  &.ye {
    width: 50px;
    height: 50px;
    background: url("../src/assets/imgs/yue.png") no-repeat center/100%;
  }
  &.jf {
    width: 50px;
    height: 50px;
    background: url("../src/assets/imgs/jfsc.png") no-repeat center/100%;
  }
  &.kq {
    width: 50px;
    height: 50px;
    background: url("../src/assets/imgs/kq.png") no-repeat center/100%;
  }
  &.wd {
    width: 50px;
    height: 50px;
    background: url("../src/assets/imgs/dingd.png") no-repeat center/100%;
  }
  &.hy {
    width: 50px;
    height: 50px;
    background: url("../src/assets/imgs/cip.png") no-repeat center/100%;
  }
  &.sm {
    width: 50px;
    height: 50px;
    background: url("../src/assets/imgs/shiz.png") no-repeat center/100%;
  }
  &.aq {
    width: 50px;
    height: 50px;
    background: url("../src/assets/imgs/mima.png") no-repeat center/100%;
  }
}

// @import "./assets/font/font.css";
.kefu {
  width: 80px;
  height: 80px;
  position: fixed;
  z-index: 10;
  right: 13px;
  bottom: 50px;
  transition: all 1s;
  opacity: 1;
  transform: translateX(0);
  z-index: 80;

  img {
    width: 100%;
    height: 100%;
  }
}

.kefu.kefu_hide {
  transform: translateX(70%);
  opacity: 0.5;
}

html,
body {
  width: 100%;
  height: 100%;
  // overflow-y: scroll;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

body {
  margin: 0;
}

#app {
  min-height: 100%;
  height: auto;
  width: 100%;
  max-width: 375px;
  //   background-color: #fafafa;
}

// 底部的 tab
#app .footer {
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 48px;
  flex: 0 0 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  //   background: #fff;
  background: #f7f7f7;
  //   box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  width: 100%;
  bottom: 0;
  height: 60px;
  z-index: 4;
}

#app .footer .footer_item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  cursor: pointer;
  color: #999999;
  width: 20%;
  //  align-self:start;
}

#app .footer .footer_item .footer_icon {
  display: inline-block;
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-bottom: 5px;
}

#app .footer .footer_item .footer_icon.footer_icon_home {
  background-image: url("../src/assets/imgs/home1.png");
}

#app .footer .footer_item .footer_icon.footer_icon_invest {
  background-image: url("../src/assets/imgs/touzi.png");
}

.footer_icon_serve {
  background-image: url("../src/assets/imgs/kefh.png");
}

#app .footer .footer_item .footer_icon.footer_icon_team {
  background-image: url("../src/assets/imgs/tuan.png");
}

#app .footer .footer_item .footer_icon.footer_icon_user {
  background-image: url("../src/assets/imgs/me.png");
}

// tab文本
#app .footer .footer_item .footer_info {
  font-size: 12px;
}

#app .footer .footer_item.footer_item_active {
  color: #606fff;
}

#app .footer .footer_item.footer_item_active .footer_icon.footer_icon_home {
  background-image: url("../src/assets/imgs/home.png");
}

#app .footer .footer_item.footer_item_active .footer_icon.footer_icon_invest {
  background-image: url("../src/assets/imgs/touzi1.png");
}
#app .footer .footer_item.footer_item_active .footer_icon.footer_icon_serve {
  background-image: url("../src/assets/imgs/kefh1.png");
}

#app .footer .footer_item.footer_item_active .footer_icon.footer_icon_team {
  background-image: url("../src/assets/imgs/tuand1.png");
}

#app .footer .footer_item.footer_item_active .footer_icon.footer_icon_user {
  background-image: url("../src/assets/imgs/my1.png");
}

.loading_wrap {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #ffffff;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 150px;
    height: 150px;
  }
}

.red_top_bg {
  width: 100%;
  max-width: 750px;
  height: 44px;
  margin: 0 auto;
  background-size: 100% 100%;
  overflow: hidden;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
}

.big_tit {
  position: absolute;
  height: 25px;
  font-size: 16px;
  color: #000000;
  line-height: 44px;
  top: 0;
  left: 40px;
}

.back_left {
  width: 20px;
  height: 20px;
  background: url(./views/img/common/back_b.png) no-repeat center center;
  background-size: auto 100%;
  margin: 12px 0 0 8px;
}

.top_right {
  position: absolute;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  top: 16px;
  right: 14px;
}

.basic_btn {
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  width: 80%;
  margin-left: 10%;
  background-size: 100% 100%;
  border-radius: 23px;
  color: #ffffff;
  background: #0f6eff;
  text-align: center;
}

.basic_btn.no_touch {
  color: rgba(255, 255, 255, 0.5);
}

.flex_center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.eye_bi {
  width: 21px;
  height: 25px;
  background: url(./views/img/user/eye2.png) no-repeat center center;
  background-size: 100% auto;

  &.eye {
    background: url(./views/img/user/eye1.png) no-repeat center center;
    background-size: 100% auto;
  }
}

.block_div {
  // border-radius: 10px;
  width: 94%;
  margin-left: 3%;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  background: #ffffff;
  border-radius: 5px;
}

.pending {
  border-bottom: 4px solid #ececec !important;
}

.color_red {
  color: #ff0000;
}

.color_green {
  color: #3cb371;
}

.color_blue {
  color: #3775f4;
}

.currency {
  font-size: 12px;
  margin-left: 3px;
  font-weight: initial;
}
</style>
